import { setSubmitting } from 'src/redux/modules/webpage/loading';
import { prooductInfoValues, soolInitialValue } from 'src/views/products/FormValues';

import { API_METHOD, authApiClient } from '../../api';
import { loadProductInfo } from './productInfo';
import { handleLoadProducts, loadProducts } from './products';

export const LOAD_PRODUCT = 'LOAD_PRODUCT';
export const SAVE_PRODUCT = 'SAVE_PRODUCT';
export const UPDATE_PRODUCT = 'UPDATE_PRODUCT';

export const saveProduct = product => ({
  type: SAVE_PRODUCT,
  product,
});

export const loadProduct = product => ({
  type: LOAD_PRODUCT,
  product,
});

export const updateProduct = product => ({
  type: UPDATE_PRODUCT,
  product,
});

export let globalResponse;

export const handleSaveProduct = (product, setProductIdForInfo) => async dispatch => {
  try {
    dispatch(setSubmitting(true));
    globalResponse = await authApiClient(API_METHOD.POST, 'product', product);
    setProductIdForInfo(globalResponse.data.result.id);
  } catch (e) {
    alert(e.message);
  } finally {
    dispatch(setSubmitting(false));
  }
};

export const handleUpdateProduct = (product, products, addToast, query) => async dispatch => {
  try {
    dispatch(setSubmitting(true));
    const response = await authApiClient(API_METHOD.PATCH, `product/${product.id}`, product);
    if (response.data.code !== 1) {
      dispatch(setSubmitting(false));
      return;
    }

    if (products == undefined) {
      addToast();
    } else {
      dispatch(handleLoadProducts(query));
    }
  } catch (e) {
    alert(e.message);
  } finally {
    dispatch(setSubmitting(false));
  }
};

export const duplicateProduct = (productId, query) => async dispatch => {
  try {
    dispatch(setSubmitting(true));
    const response = await authApiClient(API_METHOD.POST, 'product/duplicate', { productId });
    if (response.data.code === 1) {
      dispatch(handleLoadProducts(query));
    }
  } catch (error) {
    alert(error);
  } finally {
    dispatch(setSubmitting(false));
  }
};

export const handleDeleteProduct = productId => async dispatch => {
  try {
    await authApiClient(API_METHOD.DELETE, `product/${productId}`, undefined);
    const response = await authApiClient(API_METHOD.GET, 'product', undefined);
    dispatch(loadProducts(response.data.result));
  } catch (e) {
    alert(e.message);
  }
};

export const handleLoadProduct = productId => async dispatch => {
  try {
    const response = await authApiClient(API_METHOD.GET, `product/${productId}`, undefined);
    dispatch(loadProduct(response.data.result));

    const responseProductInfo = await authApiClient(API_METHOD.GET, `product/info?product_id=${productId}`, undefined);
    if (responseProductInfo.data.result[0] == undefined) {
      dispatch(loadProductInfo(prooductInfoValues));
    } else {
      dispatch(loadProductInfo(responseProductInfo.data.result[0]));
    }
  } catch (e) {
    alert(e.message);
  }
};

const product = (state = {}, action) => {
  switch (action.type) {
    case LOAD_PRODUCT: {
      if (action.product.id) {
        if (action.product.product_category_id == 1) {
          //술
          if (!action.product.sool) {
            action.product = { ...action.product, ...soolInitialValue };
          } else {
            action.product.sool.materials = action.product.sool.materials.map(material => ({
              ...material,
              label: material.name,
              value: material.name,
            }));
            action.product.sool.foods = action.product.sool.foods.map(food => ({
              ...food,
              label: food.name,
              value: food.name,
            }));

            action.product.sool.scents = action.product.sool.scents.map(scent => ({
              ...scent,
              label: scent.name,
              value: scent.name,
            }));
            action.product.sool.scent_type_first_id = action.product.sool.scent_types[0]?.value;
            action.product.sool.scent_type_second_id = action.product.sool.scent_types[1]?.value;

            delete action.product.sool.id;
            if (!action.product.sool.award) action.product.sool.award = '';
            if (!action.product.sool.sool_distinction) action.product.sool.sool_distinction = '';
            action.product = { ...action.product, ...action.product.sool };
            delete action.product.sool;
          }
        } else if (action.product.product_category_id == 3) {
          // 기물 재질은 id, name이 포함된 배열 객체 형태로 응답, 오직 하나만 선택할 수 있기 때문에 인덱스 0으로 고정한다.
          action.product.merchandise.materials_id = action.product.merchandise.materials[0].id;
          action.product.merchandise.recommended_sool_types = action.product.merchandise.recommended_sool_types.map(
            types => ({
              ...types,
              label: types.name,
              value: types.name,
            })
          );
          action.product.merchandise.option_img_urls = Array.isArray(action.product.merchandise.option_img_urls)
            ? action.product.merchandise.option_img_urls.map(image => image.image_url)
            : [];
        } else {
          action.product = { ...action.product, ...soolInitialValue };
          delete action.product.sool_category_id;
        }
        action.product.tags = action.product.tags.map(tag => ({ ...tag, label: tag.name, value: tag.name }));
        action.product.product_images = action.product.product_images.map(image => image.image_url);
        action.product.delivery_policy_id = action.product.delivery_policy && action.product.delivery_policy.id;
        action.product.set_category_id = action.product.set_category_mapping?.set_category_id;
      }
      return action.product;
    }
    default:
      return state;
  }
};

export default product;
