import { DELIVERY_TYPE, LIGHTNING_DELIVERY_NAME } from 'src/common/constants';
import { MEMBERSHIP } from 'src/redux/common';
import { initialDate, midnight, oneWeekAgo, oneWeekAgoAtMidnight, today } from 'src/utils/dateUtils';

export const productValues = {
  maker_id: 0,
  product_category_id: '1',
  maker: {},
  name: '',
  real_name: '',
  content: '',
  origin_price: '',
  discount_price: '',
  is_damhwa_box: '',
  selling_count: '',
  hit_count: '',
  star_rating: '',
  status: 'inactive',
  star_count: '',
  tags: [],
  product_images: [],
  content_mobile: '',
  delivery_policy_id: '',
  direct_purchase: 'true',
  sool_category_id: '',
  fruit_category_id: '',
  flower_category_id: '',
  nut_category_id: '',
  herb_category_id: '',
  other_category_id: '',
  situation_category_id: '',
  subtext: '',
  tag_string: '',
  alcohol_content: '',
  volumes: '',
  expire_info: '',
  storage_note: '',
  taste_note: '',
  anju_note: '',
  damhwa_note: '',
  sweet: '-1',
  carbonic: '-1',
  acidity: '-1',
  body: '-1',
  tannin: '-1',
  bitter: '-1',
  fruity: '-1',
  scent_strength: '-1',
  aftertaste: '-1',
  materials: [],
  foods: [],
  scents: [],
  scent_type_first_id: null,
  scent_type_second_id: null,
  thumbnail: '',
  award: '',
  sool_distinction: '',
  taste_standard: 'room',
  package_id: '',

  merchandise: {
    merchandise_category_id: '',
    component: '',
    size: '',
    option_img_urls: [],
    order_notification: '',
    recommended_sool_types: [],
    materials: [],
  },
};

export const soolInitialValue = {
  sool_category_id: '',
  alcohol_content: '',
  volumes: '',
  expire_info: '',
  storage_note: '',
  taste_note: '',
  anju_note: '',
  damhwa_note: '',
  sweet: '',
  carbonic: '',
  acidity: '',
  body: '',
  tannin: '',
  alcohol: '',
  bitter: '',
  fruity: '',
  scent_strength: '',
  aftertaste: '',
  scent_type_first_id: '',
  scent_type_second_id: '',
  scents: [],
  materials: [],
  foods: [],
  award: '',
  sool_distinction: '',
  taste_standard: 'room',
};

export const productOptionValues = {
  id: '',
  code: '',
  product_id: '',
  name: '',
  static_price: '',
  origin_price: '',
  discount_price: '',
  status: 'inactive',
  stock: '',
  profit_ratio: '',
  component_arr: [],
  bottle_type: '',
  product_option_mappings: [],
};

export const prooductInfoValues = {
  id: '',
  product_id: '',
  product_type: '',
  label: '',
  producer: '',
  location: '',
  expiration_info: '',
  volume: '',
  nutrient: '',
  base_material: '',
  gmo: '',
  import_note: '',
  product_name: '',
  material: '',
  component: '',
  size: '',
  release_date: '',
  maker: '',
  made_in: '',
  bottle_note: '',
  warranty_note: '소비자 분쟁해결기준에 의거함',
};

export const productOptionFilterValues = {
  page: '1',
  direct_purchase: [
    { label: `${LIGHTNING_DELIVERY_NAME}`, value: '1', checked: true },
    { label: `${DELIVERY_TYPE.MAKER_DELIVERY.NAME}`, value: '0', checked: true },
  ],
  product_category_id: [
    { label: '술', value: 1, checked: true },
    { label: '세트', value: 2, checked: true },
    { label: '기물', value: 3, checked: true },
  ],
  has_expired_item: false,
  status: [
    { label: '판매중', value: 'active', checked: true },
    { label: '품절', value: 'sold_out', checked: true },
    { label: '비활성', value: 'inactive', checked: true },
  ],
};

export const productListValues = {
  ...productOptionFilterValues,
  order: 'id',
  status: [
    { label: '판매중', value: 'active', checked: true },
    { label: '품절', value: 'sold_out', checked: true },
    { label: '준비중', value: 'upcoming', checked: true },
    { label: '비공개', value: 'private', checked: true },
    { label: '비활성', value: 'inactive', checked: true },
  ],
};

export const filterValues = {
  searchText: '',
  page: '',
  direct_purchase: '',
  status: '',
  sool_category_id: '',
};

export const userValues = {
  name: '',
  phone: '',
  email: '',
  join_date_start: initialDate,
  join_date_end: today,
  search_type: 'phone',
  search_value: '',
  type: [
    { label: '일반 회원', value: 1, checked: true },
    { label: '임직원', value: 2, checked: true },
    { label: '퇴사자', value: 4, checked: true },
  ],
  has_test_user: false,
  subscription_plan_id: 'all',
  agree_sms: [
    { label: '동의', value: true, checked: true },
    { label: '동의하지 않음', value: false, checked: true },
  ],
  agree_email: [
    { label: '동의', value: true, checked: true },
    { label: '동의하지 않음', value: false, checked: true },
  ],
  agree_push: [
    { label: '동의', value: true, checked: true },
    { label: '동의하지 않음', value: false, checked: true },
  ],
  membership_level: MEMBERSHIP.map(({ level: value, name: label }) => ({
    label,
    value,
    checked: true,
  })),
  subscription_status: [
    { no: 1, value: 'subscribing', label: '구독', checked: true },
    { no: 2, value: 'skipped', label: '쉬어가기', checked: true },
    { no: 3, value: 'canceled', label: '비구독', checked: true },
  ],
};

export const foodValues = {
  id: '',
  name: '',
  image_url: [],
};

export const scentValues = {
  id: '',
  name: '',
  image_url: [],
};

export const orderValues = {
  status: '',
  merchant_id: '',
  start_date: oneWeekAgoAtMidnight,
  end_date: midnight,
  maker_name: '',
  product_name: '',
  product_option_name: '',
  user_name: '',
  delivery_company: '',
  delivery_code: '',
  damhwabox: '',
  phone: '',
  delivery_code_not_exists: '',
  direct_purchase: '',
  delivery_type: [
    { label: DELIVERY_TYPE.DAWN_ARRIVE.NAME, value: 'dawn_arrive', checked: true },
    { label: DELIVERY_TYPE.TODAY_START.NAME, value: 'today_start', checked: true },
    { label: DELIVERY_TYPE.MAKER_DELIVERY.NAME, value: 'maker_delivery', checked: true },
  ],
  date_query: 'pay',
  is_gift: '',
};

export const orderCsListValues = {
  type: '',
  merchant_id: '',
  start_date: oneWeekAgo,
  end_date: today,
  maker_name: '',
  product_name: '',
  user_name: '',
  phone: '',
  damhwabox: '',
  sdh_charge: '',
};

export const curationValues = {
  order_no: '',
  title: '',
  sub_title: '',
  icon_img_url: [],
  search_home_image_url: [],
  banner_image_url: [],
  mobile_banner_image_url: [],
  show_no: '',
  status: 'inactive',
  products: [],
  products_id: '',
  detail_image_url: [],
  mobile_detail_image_url: [],
  type: 'event',
  coupon_image_url: [],
  mobile_coupon_image_url: [],
  start_date: '',
  end_date: '',
};

export const couponValues = {
  //erd 확인해보기
  id: '', // number, 도장 아이디
  type: 'cart', // string, 쿠폰 종류(product, cart)
  issuance_method: 'download', // string, 쿠폰 발급 방법(auto, download)
  name: '', // string, 쿠폰 이름
  content: '', // string, 쿠폰 설명
  percent: '', // number, 쿠폰 할인 퍼센트(10% -> 10 입력)
  price: '', // number, 쿠폰 할인 가격
  settlement_burden_ratio: '', // number, 쿠폰 부담률  (10.25% -> 10.25 입력)
  duration_days: '', // number, 발급 이후 쿠폰 사용 가능 기간
  available_expire_time: '', // 사용 만료일
  start_time: '', // number, 발급 시작 가능 시간
  expire_time: '', // number, 쿠폰 + 도장 만료 시간
  status: 'active', // string, 상태 (inactive, active, deprecated)
  condition_obj: {
    all: '1', // 전체일 경우 1, 그 이외의 경우 서버에 보내지 않음
    min_price: '', // number, 최소 구매 가격
    max_discount_price: '', // number,  최대 할인 금액(할인율로 입력되었을 시에만)
    subscription_plan_id_arr: [],
  },
  discount_condition_range: '1',
  discount_category: '',
  discount_condition_id_list: '',
  exclude_condition_range: '0',
  exclude_category: '',
  exclude_condition_id_list: '',
  exclude_condition_obj: {},
  expiration_action: '',
  max_issuance_amount: '',
  max_having_amount: '1',
  register_code: '',
  url: '',
  has_notification: false,
  notification_buffers: [],
  curation_display_id: '',
  is_subscriber_only: [],
  discount_months: '',
  basic_damhwabox: [],
  soju_damhwabox: [],
  yakchungju_damhwabox: [],
  is_unsubscribe_on_discount_end: false,
};

export const makerValues = {
  maker_type_id: '',
  name: '',
  ceo_name: '',
  email: '',
  phone_number: '',
  region: '',
  address: '',
  registration_number: '',
  report_number: '',
  introduction: '',
  mobile_introduction: '',
  tags: [],
  official_name: '',
  maker_image_url: [],
  status: 'inactive',
  account_number: '',
  account_holder: '',
  bank_name: '',
  note: '',
};

export const initialMakerPolicyValues = {
  maker_id: '', // 양조장 아이디
  name: '', // 배송 정책의 고유이름
  delivery_company: '', // 배송사
  regular_delivery_price: '', // 일반 배송비
  additional_delivery_price: '', // 제주 산간 지역 추가 배송비
  min_total_for_free_delivery: '', // 배송비 무료 적용을 위한 최소 구매 금액
  delivery_note: '', // 상품 상세페이지에 안내되는 배송 기간
  separate_delivery: [], // 상품 개수 별로 배송비가 책정되는 지 여부
  free_special_delivery_type: '1',
};

export const deliveryValues = {
  order_arr: [],
  delivery_company_for_delivery: '', // DOM id unique를 위해
  delivery_code_for_delivery: '', // DOM id unique를 위해
};

export const subscriptionScheduleValues = {
  name: '',
  code: '',
  subscription_plan_id: '1',
  product_option_id: '',
  billing_date: '',
  shipping_date: '',
  stock: '',
  concept_image: [],
  vertical_concept_image: [],
  detail_images: [],
  mobile_detail_images: [],
  box_content_image: [],
};

export const subscriptionProductValues = {
  name: '',
  code: '',
  price: '',
  once_price: '',
  period: '',
};

export const addressValues = {
  receiver_name: '',
  phone_number: '',
  zipcode: '',
  base_address: '',
  detail_address: '',
};

export const subscriptionOrderValues = {
  subscription_plan_id: '0',
  user_name: '',
  user_phone: '',
  user_email: '',
  merchant_id: '',
  address: '',
  count: '',
  status: '',
  subscription_schedule_id: '',
  initial_order: '',
  start_date: initialDate,
  end_date: today,
  coupon_name: '',
};

export const subscriptionCompleteValues = {
  subscription_plan_id: '0',
  count: '',
  status: '',
  subscription_schedule_id: '',
  initial_order: '',
  start_date: oneWeekAgo,
  end_date: today,
  search_type: 'user_phone',
  search_value: '',
  repayment: '0',
};

export const pointValues = {
  point: '',
  message: '',
  event: '',
};

export const pointHistoryValues = {
  user_name: '',
  user_email: '',
  user_phone: '',
};

export const reviewValues = {
  start_date: oneWeekAgo,
  end_date: today,
  product_name: '',
  product_category_id: '',
  comment: '',
  step: '',
  score_start: 0,
  score_end: 5,
  damhwabox: '',
};

export const photoReviewValues = {
  start_date: oneWeekAgo,
  end_date: today,
  product_name: '',
  product_category_id: '',
  comment: '',
  score_start: 0,
  score_end: 5,
  damhwabox: '',
};

export const bannerValues = {
  title: '',
  url: '',
  banner_image_url: [],
  mobile_banner_image_url: [],
  showing_place: '1',
  status: false,
};

export const subscriptionHintValues = {
  subscription_schedule_id: '',
  async_sool_name: {},
  sool_id: '',
  img_url: [],
  comment: '',
  alcohol_content: '',
  volumes: '',
  price: '',
  expire_info: '',
};

export const initialPopupValues = {
  id: '', // 팝업 아이디
  title: '', // 팝업 이름
  startDatetime: '', // 시작일, 종료일
  endDatetime: '',
  redirectStatus: false, // 연결링크
  redirectUrl: '',
  image: [], // 팝업 이미지
  status: false, // 노출여부
  //메인 랜딩
  mainLanding: false,
  //마켓 랜딩
  marketLanding: false,
  //마켓 리스팅
  marketListing: false,
  marketListingStatus: '', // 'all' / 'some'
  marketListingArray: '', // "123,100,233"
  //마켓 상세
  marketDetail: false,
  marketDetailStatus: '', // 'all' / 'some'
  marketDetailArray: '', // "123,100,233"
  //마켓 이벤트
  marketEvent: false,
  marketEventStatus: '', // 'all' / 'some'
  marketEventArray: '', // "123,100,233"
  // 구독 랜딩
  subscribeLanding: false,
  // 구독 상세
  subscribeDetail: false,
};

export const curationInitialValues = { type: '', title: '', sub_title: '', status: '' };

export const initialAdminUserFormValues = {
  name: '',
  email: '',
  password: '',
};

export const initialAdminUserListFormValues = {
  name: '',
  email: '',
};

export const pushFormValues = {
  title: '',
  body: '',
  reserved_at: '',
  image_url: [], // 선택
  url: '', // 선택
  label: '', // 선택
  memo: '', // 선택
  submitType: '',
};
